.footer {
  background: linear-gradient(
    90deg,
    #213e61 5.74%,
    #093b83 48.45%,
    #171c70 98.97%
  );
  .links {
    padding: 90px 0;
    .spread {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        color: #ffffff !important;
        font-weight: bold;
        font-size: 15px;
      }
      .logo {
        width: 120px;
        height: 31px;
        margin: 16px 24px 16px 0;
        // background: #fff;
      }
    }
  }
  .cp {
    padding: 90px 0;
    .extra {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .socials {
        display: flex;
        gap: 20px;
        .social {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-color: #e5e5e5;
          border-radius: 20px;
        }
      }
    }
  }
}
