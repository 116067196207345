.project {
  width: 90%;
  margin: 10% 5%;
  background: #fffdfd;
  box-shadow: 0px 2px 5px rgba(50, 89, 210, 0.5);
  border-radius: 6px;
  overflow: hidden;
  .title {
    display: block;
    padding: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 10px 0px 10px 10px;
    text-overflow: ellipsis;
    white-space:nowrap;
    font-family: MontserratMedium;
  }
  .links {
    padding: 5px;
    padding-top: 0;
    display: flex;
    margin: 0 0px 15px 10px;


    .carbonDetailBtn,.projectDetailBtn{
      width: 100px;
      height: 30px;
      background-color: #fff;
      border-radius: 6px;
      border: none;
      margin-right: 10px;
      border: 1px solid #000;
      font-family: MontserratMedium;

      &:hover{
        background-color: #3259D2;
        color: #fff;
        border: none;

      }
    }
  }
  .cover {
    height: 200px;
  }


  :global{
    .slick-prev {
      width: 50px;
      height: 50px;
      background-color: #3259D2;
    }
    .slick-prev::before{
      font-size: 50px;
      color: #3259D2;
    }
  }
}
