.header {
  .menus {
    background-color: transparent !important;
  }
  :global {
    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
      background-color: transparent !important;
    }
    .ant-layout-header {
      background-color: transparent !important;
    }
    .ant-menu-item-selected {
      background-color: transparent !important;
    }
    .ant-menu-item:hover {
      background-color: transparent !important;
    }

    .ant-menu-item:active {
      background-color: transparent !important;
    }
  }
  .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    // background: rgba(255, 255, 255, 0.2);
  }
}
