.pagination{
    width: 100%;
    margin: 0 auto;
    // position: absolute;
    margin-top: 25px;
    margin-bottom: 25px;
}
.tHeader{
    text-align: left;
    height: 58px !important;
    line-height: 58px !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    font-family:IBMPlexSansMedium;
}
.tBodys{
    text-align: left !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    font-family:IBMPlexSansMedium;
}
.divideLine{
    width: 100% !important;
    height: 2px;
    background: linear-gradient(90deg, #33388E 0%, #FCFCFF 51.56%, #32388D 100%) !important;
    border-radius: 8px !important;
}

.noData{
    position: absolute;
    color: #FCFCFF;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}