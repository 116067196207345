body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('./assets/fonts/Montserrat-Regular-8.otf');
}
@font-face {
  font-family: 'MontserratMedium';
  src: url('./assets/fonts/Montserrat-Medium-7.otf');
}
@font-face {
  font-family: 'IBMPlexSansBold';
  src: url('./assets/fonts/IBMPlexSans-Bold.ttf');
}
@font-face {
  font-family: 'IBMPlexSansRegular';
  src: url('./assets/fonts/IBMPlexSans-Regular.ttf');
}
@font-face {
  font-family: 'IBMPlexSansMedium';
  src: url('./assets/fonts/IBMPlexSans-Medium.ttf');
}