.transparent-select .ant-select-selector {
  background: transparent !important;
  color: #fff !important;
  /* border: none!important; */
}

.transparent-select .ant-select-arrow .anticon {
  color: #fff !important;
}

.transparent-dropdown {
  /* background: transparent !important; */
  /* color: #fff !important; */
}
