@import "~antd/dist/antd.less";
@import "./components/project-select/index.css";
@import "./components/SearchPanel/index.css";
@import "./components/ProjectTabs/index.css";
@import "./components//TagsGroup/index.css";
@import "./components/ProjectCheckBox/index.css";
.container {
  max-width: 1256px;
  margin: 0 auto;
}
