.largeScreenData{
    width: 100%;


    .dataA{
        width: 100%;
        display: flex;
        flex-direction: row;


        .dataAImg{
              width: 50%;
        }
        .dataBImg{
            width: 50%;
            margin-top: 145px;
        }

        img{
            width: 100%;
            height: 100%;
        }

        .dataListA,.dataListB{
            margin-left: 29px;
            width: 45%;
            display: flex;
            flex-direction: column;
            background: linear-gradient(180deg, #3851A9 0%, rgba(47, 21, 101, 0) 100%);
            filter: drop-shadow(0px 1px 4px rgba(255, 255, 255, 0.25));
            border-radius: 8px;
            padding-bottom: 17px;
            box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.25);
            position: relative;


            .title{
                margin: 10px 10px 0 19px;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                padding-bottom: 9px;
                color: #fff;
            }

            .table{
                width: 100%;
                padding: 0 10px;
            }
            .tHead{
              width: 100%;
            }
            .tRow{
                line-height: 50px;
            }
            .largeScreenTable{
                margin-top: 10px;
                padding: 0;
                width: 100%;
                height: 210px;
                overflow: auto;
                color: #fff;
            }
            .divideLine{
                position: absolute;
                top: 100px;
                margin: 0px  0 10px 0;
                width: 100%;
                height: 3px;
                background: linear-gradient(90deg, #333A90 0%, #FFFFFF 53.65%, #303B90 100%);
                border-radius: 3px;
            }

        }
        .dataListB{
            margin-top: 145px;
            margin-left: 0 !important;
            margin-right: 29px;
        }
        .seeDetailA,.seeDetailB{
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .seeDetailBtn {
                margin: 0 10px;
                margin-top: 20px;
                width: 150px;
                height: 40px;
                border-radius: 6px;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                background-color: rgba(0, 0, 0, 0);
                font-size: 15px;
                font-weight: 500;
                color: #fff;
            }
        }
        .seeDetailB{
            justify-content: flex-start;
        }
    }

}
    .line{
        margin: 10px 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, #333A90 0%, #FFFFFF 53.65%, #303B90 100%);
        border-radius: 3px;
    }

.tableButton{
    width: 55px;
    height: 25px;

    border-radius: 6px;
    border: none;
    font-size: 14px;
    padding: 0;
    line-height: 25px;
}
.greenBtn{
 background: #5ED3A7;
}
.redBtn{
    background-color: #D01864;
}