:global{
    .rlc-calendar{
        padding: 0 20px 0 35px;
        width: 100% !important;
        border: none !important;
        box-shadow: none;
        text-align: left;

    }
    .rlc-details{
        display: none;
    }
    .rlc-days-label,.rlc-days{
        color: #fff;
    }
    .rlc-month-and-year-wrapper{
        border: none;
        width: 95%;
    }
    .rlc-prev-year,.rlc-prev-month,.rlc-month-and-year,.rlc-next-month,.rlc-next-year{
        color: #fff;
    }
    .rlc-month-and-year{
        width: 100%;
        text-align: left;
        margin-left: 10px;
    }
    .rlc-next-month,.rlc-prev-month{
        position: absolute;
        right: 5px;
        top:50%;
        width: 30px;
        height: 30px;
        background-color: hsl(235, 94%, 65%);
        border-radius: 50%;

        background-image: url('../../assets/images/largeScreen/jiantou.png');
        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-position: center;
    }
    .rlc-prev-month{
        position: absolute;
        right: 0;
        left: 5px;
        transform: rotateY(180deg);
    }

    .rlc-prev-year,.rlc-next-year{
        display: none;
    }
    .rlc-day-end-selection, .rlc-day-inside-selection, .rlc-day-selected, .rlc-day-start-selection{
        background: rgba(94, 212, 165, 0.6);
        //border-radius: 50%;
    }
    .rlc-day-end-selection:hover, .rlc-day-inside-selection:hover, .rlc-day-selected:hover, .rlc-day-start-selection:hover {
        background: #5ED4A5;
    }
    // .rlc-day-start-selection{
    //     border: 2px solid #5ED4A5;
    //     border-radius: 50%;
    // }
}