.largeScreen2{
    width: 100%;
    background: linear-gradient(229.24deg, #121C71 0%, #30186B 45.46%, #034087 69.19%, #1B155F 88.82%);

    .content{
        padding-top: 64px;


        .charts{
            padding: 0 28px 32px 28px;
            padding-bottom: 88px;
            background: rgba(0, 32, 95, 0.78);
            box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);

            .displayData{
                display: flex;

                .leftData{
                    width: 30%;
                    margin-right: 15px;

                    .basicInfo{
                        width: 360px;
                        background: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        border-radius: 10px;
                        .infoOption{
                            margin-top: 35px;
                            margin-bottom: 26px;
                            padding-bottom: 58px;
                            padding-left: 23px;
                            color:  #FFFCFC;;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 8px;

                            p{
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 22px;
                                white-space: pre-wrap;
                                position: relative;

                                .capacity{
                                    position: absolute;
                                    right: 60px;
                                    top: -15px;
                                }
                                .capacity20{
                                    position: absolute;
                                    left: 130px;
                                    top:-17px;
                                }

                                .designCarbon,.actualCarbon,.percentageSon{
                                    height: 12px;
                                    background-color: #3258D2;
                                    border-radius: 6px;
                                    display: inline-block;
                                }
                                .actualCarbon{
                                    background: #F9B02B;
                                }
                                .percentageSon{
                                    background: #D01864;
                                }

                                .percentageFather{
                                   height: 12px;
                                   display: inline-block;
                                   background-color: #FFFCFC;
                                   border-radius: 6px;
                                   width: 65%;
                                   line-height: 14px;
                                }
                            }
                        }
                    }
                    .liveView{
                        height: 410px;
                        background-color: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                        .liveViewImages{
                            width: 100%;
                            position: relative;

                            .liveViewImg1{
                                width: auto;
                                height: 123px;//-30px
                                position: absolute;
                                left: 17%;
                                top: 17px;
                                z-index: 1;
                                background-size: contain;
                                background-image: url('../../assets/images/largeScreen2/liveView1.png');
                            }
                            .liveViewImg2{
                                height: 134px;
                                position: absolute;
                                left: 14%;
                                top: 49px;
                                z-index: 2;
                                background-size: contain;
                                background-image: url('../../assets/images/largeScreen2/liveView2.png');
                            }
                            .liveViewImg3{
                                height: 149px;
                                position: absolute;
                                top: 87px;
                                left: 10%;
                                z-index: 3;
                                background-size: contain;
                                background-image: url('../../assets/images/largeScreen2/liveView3.png');
                            }
                            .liveViewImg4{
                                height: 163px;
                                position: absolute;
                                top: 141px;
                                left: 7%;
                                z-index: 4;
                                opacity: 1;
                                background-size: contain;
                                background-image: url('../../assets/images/largeScreen2/liveView4.png');
                            }
                        }

                    }
                }
                .rightData{
                    flex: 1;

                    .reasonsExceedingStandard{
                        width: 100%;
                        margin-bottom: 28px;

                        background: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                        position: relative;

                        .tableDisplay{
                            padding:0 20px 40px 20px;
                            margin-top: 25px;

                            .largeScreenTable{
                                max-height: 380px;
                                overflow: auto;
                            }
                        }

                    }
                }
            }
        }
    }

}

//表
.table{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFCFC;
    font-family: MontserratRegular;

    .tHeader{
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 20px;

        .tableHead{
            width: 100%;
            height: 45px;
            line-height: 40px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .downUp, .sortUp{
            margin-left: 10px;
            display: inline-block;
            width: 14px;
            height: 20px;
            background-image: url('../../assets/img/project-view/down-arrow.png');
            background-position: bottom;
        }
        .sortUp{
            transform: rotateX(180deg);
        }
    }
    .tBody{
        padding-top: 10px;
        width: 100%;
        display: flex;
        //align-items: center;
        justify-content: space-between;
        text-align: center;
        font-size: 16px;

        &::-webkit-scrollbar {
            //隐藏滚动条
            width: 0 !important;
        }
        .trRow{
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
        }
    }

    .tDivideLine{
        width: 95%;
        height: 2px;

        background: linear-gradient(90deg, #333A90 -51.01%, #FFFFFF 2.64%, #303B90 48.99%);
        border-radius: 3px;
    }
}


