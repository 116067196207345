.my-checkbox {
  span {
    color: #fff !important;
  }
}

.my-checkbox .ant-checkbox-inner {
  background: transparent !important;
}

.my-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: transparent !important;
}

.my-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  border: 1px solid #fff !important;
}

.my-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #fff !important;
}
