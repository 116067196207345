.home {
  &_brand {
    padding-top: 64px;
    .content {
      width: 100%;
      position: relative;
     // padding-bottom: 100px;
      background-color: #fff;

        .banners{
          width: 100%;
          box-sizing: border-box;
        }

       :global{
         .slick-dots{
            bottom: 119px;
         }
          .slick-dots li{
            width: 10px;
            height: 10px;
            background-color: #3259D2;
            border-radius: 0;
            border-radius: 50%;
          }

          .slick-dots li button::before{
              content: '';
          }

          li.slick-active{
            color: #fff;
            background-color: #fff;
          }
       }
       .loadingBtn{
         display: block;
          width: 100%;
          text-align: center;
          margin-top: -80px;
          position: absolute;
          z-index: 10;
          display: flex;
          justify-content: center;

        .round{
           width: 172px;
           height: 172px;
           background-image: url('../../assets/images/firstPage/round.png');

           display: flex;
           align-items: center;
           flex-direction: column;


           .switchBtn{
             width: 39px;
             height: 66px;
             background-image: url('../../assets/images/firstPage/Vector.png');
             margin-top: 28px;
             margin-bottom: 31px;
             padding-top: 10px;
             display: flex;
             justify-content: center;

             .columnLine{
                width: 4px;
                height: 15px;
                background-color: #3259D2;
             }
           }

           .arrows{
             height: 62px;
             display: flex;
             flex-direction: column;

            .arrow{
              margin-top: -8px;
            }
           }
        }

      }
    }

    background: linear-gradient(
      229.24deg,
      #121c71 0%,
      #30186b 45.46%,
      #034087 69.19%,
      #1b155f 88.82%
    );


  }
  &_projects {
    padding: 140px  50px 40px 50px;
    background: linear-gradient(
      180deg,
      rgba(231, 230, 243, 0) 0%,
      #e1edff 100%
    );
    .list {
      .project_card {
        background: #fffdfd;
        box-shadow: 0px 2px 5px rgba(50, 89, 210, 0.5);
        border-radius: 6px;
      }

      :global{
         .slick-dots li{
            width: 10px;
            height: 10px;
            background-color: #3A85E8;
            border-radius: 0;
            border-radius: 50%;
          }

          .slick-dots li button::before{
              content: '';
              // color: #3259D2 !important;
          }

          li.slick-active{
              color: #fff;
              background-color: #fff;
              border: 1px solid #3A85E8;
          }


          .slick-next,.slick-prev{
            width: 50px !important;
            height: 50px !important;
            background-color: #3259D2 !important;
            border-radius: 50%;

          }
          .slick-next{
            right: -50px !important;
          }
          .slick-prev{
            left: -50px !important;
          }

          .slick-next::before{
            content: url('../../assets/images/firstPage/rightArrows.png') !important;
          }

          .slick-prev::before{
            content: url('../../assets/images/firstPage/leftArrows.png') !important;
          }
      }

    }
    .title {
      display: flex;
      justify-content: center;
      font-size: 32px;
      margin: 10px 0;
      margin-bottom: 40px;
      font-family: MontserratRegular;
    }
  }
  &_screen {
    padding: 40px 50px;
    background: linear-gradient(90deg, #213E61 5.74%, #093B83 48.45%, #171C70 98.97%);

    .title {
      display: flex;
      justify-content: center;
      font-size: 32px;
      margin: 10px 0;
      margin-bottom: 40px;
      color: #fff;
    }
  }
}
