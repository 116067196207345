.custom-tag {
  background: linear-gradient(
    112.83deg,
    rgba(255, 255, 255, 0.1476) 0%,
    rgba(255, 255, 255, 0.144) 110.84%
  ) !important;
  border: 0.5px solid #ffffff !important;
  box-shadow: 0px 2.675px 7.35625px rgba(0, 0, 0, 0.02) !important;
  backdrop-filter: blur(28.0875px) !important;
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 6px !important;
  color: #ffffff !important;
}

.custom-tag .ant-tag-close-icon {
  color: #ffffff !important;
}
