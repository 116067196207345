.largeScreen {
    padding-bottom: 88px;
    /* 蓝紫 */
    background: linear-gradient(229.24deg, #121C71 0%, #30186B 45.46%, #034087 69.19%, #1B155F 88.82%);

    .content {
        padding-top: 64px;

        .menuDividingLine {
            display: block;
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg, #213E61 0%, rgba(253, 253, 255, 0.520833) 47.92%, #171C70 100%);
            margin-bottom: 22px;
        }

        .charts {
            padding: 0 28px 32px 28px;
            background: rgba(0, 32, 95, 0.78);
            box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);

            .largeScreenData {
                width: 100%;
                display: flex;

                .carbonRecord {
                    width: 30%;
                    display: flex;
                    flex-direction: column;

                    .monthRecord {
                        position: relative;
                        text-align: center;
                        height: 400px;
                        box-sizing: border-box;
                        background: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        border-radius: 10px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                        .dateArea {
                            margin-top: 20px;
                        }

                        .detailBtn {
                            position: absolute;
                            width: 60px;
                            height: 30px;
                            right: 87px;
                            top: 85px;
                            border: none;
                            color: #fff;

                            background: #3259D2;
                            border-radius: 30px;
                        }
                    }

                    .yearRecord {
                        margin-top: 27px;
                        height: 420px;
                        box-sizing: border-box;
                        text-align: center;
                        background: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        border-radius: 10px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        color: #fff;

                        .yearEchartRecord {
                            position: relative;

                            .yearWrite {
                                position: absolute;
                                left: 0;
                                top: 18px;
                                width: 100%;
                                text-align: center;
                                display: inline-block;

                                color: #fff;
                                white-space: pre-wrap;
                            }
                        }

                        .chartsContent {
                            padding: 0 5%;
                            position: relative;
                            width: 100%;

                            .monthWrite {
                                position: absolute;
                                right: 20px;
                                bottom: 60px;
                            }
                        }

                        .yearBarData {
                            //width: 100%;
                            //准确的宽度值，试试，卡在渲染上的时候，canvas会不会满屏
                            width: 320px !important;
                            height: 380px !important;
                        }
                    }
                }

                .carbonStateRecord {
                    width: 40%;

                    .dotsCharts {
                        margin-top: -40px;
                        width: 100%;
                        height: 560px;
                        background-image: url('../../assets/images/largeScreen/map.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: 20px;
                        position: relative;

                        .introduce {
                            position: absolute;
                            bottom: 37px;
                            left: 7px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 22px;
                            color: #fff;

                            .dotChartsTitle {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }
                    }

                    .roundCharts {
                        margin-left: 15px;
                        height: 325px;
                        text-align: center;
                        background: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        border-radius: 10px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        position: relative;

                        .chartsArea {
                            border-radius: 8.375px;
                            display: flex;

                            .optionsDes {
                                position: absolute;
                                bottom: 80px;
                                width: 30%;
                                text-align: left;
                                padding-left: 7px;
                            }

                            .roundData {
                                margin-top: 40px;
                                margin-left: 10px;
                                flex: 1;

                                .roundDataYearRecord {
                                    width: 449px !important;
                                    top: -20px;
                                    right: -40px;
                                    height: 240px !important;
                                }
                            }
                        }
                    }
                }

                .carbonData {
                    width: 30%;
                    margin-left: 15px;

                    .carbonTableShow {
                        text-align: center;
                        height: 400px;
                        box-sizing: border-box;
                        background: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                        position: relative;


                        .table {
                            width: 100%;
                            padding: 5px 10px 0px 10px;
                        }

                        .tHead {
                            width: 100%;
                        }

                        .tRow {
                            line-height: 26px !important;
                        }

                        .tableButton {
                            width: 55px;
                            height: 25px;

                            border-radius: 6px;
                            border: none;
                            font-size: 14px;
                            padding: 0;
                            line-height: 25px;
                        }

                        .greenBtn {
                            background: #5ED3A7;
                        }

                        .redBtn {
                            background-color: #D01864;
                        }

                        .largeScreenTable {
                            margin-top: 10px;
                            padding: 0;
                            width: 100%;
                            height: 240px;
                            overflow: auto;
                            color: #fff;
                        }

                        .divideLine {
                            position: absolute;
                            top: 115px;
                            margin: 0px 0 10px 0;
                            width: 100%;
                            height: 2px;
                            background: linear-gradient(90deg, #333A90 0%, #FFFFFF 53.65%, #303B90 100%);
                            border-radius: 3px;
                        }
                    }


                    .barCharts {
                        margin-top: 27px;
                        text-align: center;
                        height: 420px;
                        box-sizing: border-box;
                        background: linear-gradient(210.96deg, rgba(24, 59, 111, 0.5) 0.01%, rgba(40, 24, 106, 0.5) 45.32%, rgba(20, 30, 114, 0.5) 104.81%);
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;


                        .lineCharts {
                            padding: 0 10px;
                            text-align: left;
                            font-size: 16px;
                            position: relative;


                            .lineChartData {
                                width: 336px !important;
                                height: 320px !important;
                            }

                            .des1 {
                                position: absolute;
                                bottom: 10px;
                                display: flex;
                                align-items: center;
                                margin-left: 20px;

                                .exclamationMark {
                                    display: inline-block;
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 7px;
                                    background-image: url('../../assets/images/largeScreen/exclamationMark.png');
                                    background-position: bottom;
                                }

                                color: #FF2D2E;
                            }

                            .des2 {
                                position: absolute;
                                bottom: -15px;
                                margin-left: 47px;
                                color: #FFFFFF;

                                .percentage {
                                    margin-left: 3px;
                                    color: #FF2D2E;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

// charts title styles
.titleLine {
    display: inline-block;
    width: 90%;
    height: 2px;
    background: linear-gradient(90deg, #183C6F 0%, #FFFFFF 51.36%, #131E72 100%);
    position: absolute;
    border-radius: 6px;
}

h3 {
    position: relative;
    margin: 0;
    width: 100%;
    height: 60px;
    text-align: left;
    color: #fff !important;

    padding-left: 21px;
    color: #fff;
    line-height: 54px;

    font-weight: 500;
    font-size: 24px;

    /* Linear */

    background: linear-gradient(90deg, #173D6F 0%, rgba(41, 23, 105, 0.5) 53.65%, #121E72 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    z-index: 111;


}

// 散点图的圆点
.options {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}

.redDot,
.yellowDot,
.greenDot,
.blueDot,
.purpleDot,
.pinkDot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #D01864;
    margin-right: 4px;
    border-radius: 50%;

}

.yellowDot {
    background: #F2F832;
}

.greenDot {
    background: #61DDAA;
}

.blueDot {
    background: #5889F0;
}

.purpleDot {
    background: #5556E3;
}

.pinkDot {
    background: #AE30DA;
}

//title styles
.title {
    height: 83px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    line-height: 22px;
    color: #fff;
    padding: 30px 0;

}

.rowLine {
    height: 2px;
    margin-top: -20px;
    margin-bottom: 26px;
    /* Linear */

    background: linear-gradient(90deg, #183C6F 0%, #FFFFFF 51.36%, #131E72 100%);
    border-radius: 6px;
}