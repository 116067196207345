.custom-project-tabs p {
  margin: 0;
}
.custom-project-tabs > .ant-tabs-card .ant-tabs-content {
  /* height: 120px; */
  margin-top: -16px;
}
.custom-project-tabs > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  color: #fffcfc;
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  background: #052762;
}
.custom-project-tabs .ant-tabs-tab,
.custom-project-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fffcfc !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.custom-project-tabs > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.custom-project-tabs > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .custom-project-tabs > .ant-tabs-card .ant-tabs-tab {
  background: transparent !important;
  border-color: transparent !important;
}
.custom-project-tabs > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"]
  .custom-project-tabs
  > .ant-tabs-card
  .ant-tabs-tab-active {
  background: #052762 !important;
  border-color: #052762 !important;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme="compact"] .custom-project-tabs > .ant-tabs-card .ant-tabs-content {
  /* height: 120px; */
  margin-top: -8px;
}
[data-theme="dark"] .custom-project-tabs > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme="dark"]
  .custom-project-tabs
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme="dark"] .custom-project-tabs > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
